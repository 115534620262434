import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/Layout/Layout';
import { Transition } from 'react-transition-group';
import { PDFObject } from 'react-pdfobject';
import classNames from 'classnames';
import SEO from '../components/seo';
import colors from '../components/_colors.scss';

const TRANSITION_DURATION = 300;

const DEFAULT_STYLE = {
	transition: `transform ${TRANSITION_DURATION}ms cubic-bezier(0, 0.25, 0, 1), opacity ${TRANSITION_DURATION}ms ease`,
	transform: 'translate3d(0, 30px, 0)',
	opacity: 0,
};

const TRANSITION_STYLES = {
	entering: {
		transform: 'translate3d(0, 30px, 0)',
		opacity: 0,
	},
	entered: {
		transform: 'translate3d(0, 0, 0)',
		opacity: 1,
	},
	exited: {
		transform: 'translate3d(0, 0, 0)',
		opacity: 0,
	},
	exiting: { transform: 'translate3d(0, 0, 0)' },
};

const Press = styled.section`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;

	@media (min-width: 1024px) {
		flex-direction: row;
		min-height: calc(100vh - 226px);
		position: relative;
		border-bottom: 1px solid;

		&:last-of-type {
			border-bottom: 0;
		}
	}
`;

const Section = styled.section`
	&.title {
		padding: 20px;
	}
	&.list {
		border-top: 1px solid;
	}
	@media (min-width: 1024px) {
		width: 50%;
		display: inline-block;

		&.title {
			padding: 100px 50px;
			position: sticky;
			top: 115px;
			height: calc(100vh - 226px);
		}
		&.list {
			border-top: none;
			border-left: 1px solid;
		}
	}
`;

const PressLink = styled.a`
	color: inherit;
	cursor: pointer !important;
	text-decoration: none;
	@media (min-width: 1024px) {
	}
`;

const PageTitle = styled.h2`
	font-size: 24px;

	@media (min-width: 1024px) {
		font-size: 2.6666666667em;
	}
`;

const PressArticle = styled.article`
	transition: background-color 400ms ease, color 400ms ease;

	&:first-of-type {
		header {
			border-top: 0;
		}
	}

	&:hover {
		background-color: ${colors.navy};
		color: ${colors.background};

		img {
			filter: brightness(0) invert(1);
		}
		&.no-hover {
			background-color: ${colors.background};
			color: ${colors.navy};
		}
	}
`;

const PodcastWrapper = styled.article`
	border-bottom: 1px solid currentColor;

	&:last-of-type {
		border-bottom: none;
		margin-bottom: -1px;
	}
`;

const Podcast = styled.div`
	padding: 20px;
	border-bottom: 1px solid currentColor;
`;

const PressHeader = styled.header`
	border-top: 1px solid;

	padding: 20px 20px 0;

	img {
		transition: filter 0.25s;
	}

	@media (min-width: 1024px) {
		border-bottom: 1px solid;
		padding: 30px 25px;
	}
`;

const PressLogo = styled.img`
	max-height: 32px;
	transition: filter 0.25s;
	object-fit: contain;

	@media (min-width: 1024px) {
		max-height: 42px;
	}
`;

const PressTitle = styled.h3`
	font-size: 18px;
	padding: 20px;

	@media (min-width: 1024px) {
		font-size: 2em;
		padding: 30px 25px 20px;
	}
`;

const PodcastTitle = styled(PressTitle)`
	border-bottom: 1px solid currentColor;
`;

const PressSubtitle = styled.p`
	padding-top: 20px;

	a {
		color: inherit;
		text-decoration: none;
		border-bottom: 1px solid;
		transition: border-bottom 400ms ease;
		cursor: pointer;

		&:hover {
			border-color: transparent;
		}
	}
`;

const Year = styled.p`
	padding: 0 20px 20px;
	color: ${colors.darkGreen};

	@media (min-width: 1024px) {
		padding: 0 25px 50px;
		max-width: 75ch;
	}
`;

const PDFWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.66);
	opacity: 0;
	pointer-events: none;
	transition: opacity 400ms ease;
	z-index: 999;
	&.open {
		opacity: 1;
		pointer-events: all;
	}
`;

const PDF = styled.article`
	position: fixed;
	top: 10px;
	left: 10px;
	right: 10px;
	bottom: 10px;
	z-index: 999;
	border: 1px solid;
	#pdfobject {
		height: 100%;
	}
	embed {
		max-width: 100%;
	}

	@media (min-width: 768px) {
		top: 10%;
		left: 10%;
		right: 10%;
		bottom: 10%;
	}
`;

const PDFClose = styled.button`
	position: fixed;
	top: 20px;
	right: 20px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 125%;
`;

const PressPage = () => {
	const pressData = useStaticQuery(graphql`
		{
			allSanityPressList {
				nodes {
					list {
						logo {
							asset {
								url
							}
						}
						headline
						title
						year
						url
						pdfLink
						pdf {
							asset {
								url
								originalFilename
							}
						}
					}
				}
			}
			allSanityPodcastList {
				nodes {
					list {
						title
						logo {
							asset {
								url
								fluid {
									base64
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									sizes
								}
							}
						}
						other
						applePodcast
					}
				}
			}
		}
	`);
	const [pressList] = useState(pressData.allSanityPressList.nodes[0].list);
	const [invterviews] = useState(pressData.allSanityPodcastList.nodes[0].list || []);
	const [currentPDF, setCurrentPDF] = useState(null);

	return (
		<React.Fragment>
			<SEO title='Press | Marquise Stillwell' />
			<Transition
				in={currentPDF ? true : false}
				mountOnEnter
				unmountOnExit
				appear
				timeout={{
					enter: 100,
					exit: TRANSITION_DURATION,
				}}>
				{state => (
					<PDFWrapper
						onClick={e => {
							const { currentTarget } = e
							if (currentTarget.id !== 'pdfobject') {
								setCurrentPDF(null)
							}
						}}
						className={classNames('', {
							open: currentPDF,
						})}>
						<PDFClose onClick={() => setCurrentPDF(null)}>Close</PDFClose>
						{currentPDF && (
							<PDF
								style={{
									...DEFAULT_STYLE,
									...TRANSITION_STYLES[state],
								}}>
								<PDFObject url={currentPDF} />
							</PDF>
						)}
					</PDFWrapper>
				)}
			</Transition>

			<Press>
				<Section className='title'>
					<PageTitle>Press</PageTitle>
					<PressSubtitle>
						For press inquiries, please contact{' '}
						<a href='mailto:hello@quise.design' target='_blank' rel='noreferrer noopener'>
							hello@quise.design
						</a>
					</PressSubtitle>
				</Section>
				<Section className='list'>
					{pressList.length > 0 && (
						<React.Fragment>
							{pressList.map((article, index) => (
								<PressArticle key={index}>
									{article.pdfLink ? (
										<PressLink
											onClick={() => {
												setCurrentPDF(article.pdf.asset.url)
											}}>
											<PressHeader>
												<PressLogo src={article.logo.asset.url} alt={article.title} />
											</PressHeader>
											<PressTitle>{article.headline}</PressTitle>
											<Year>{article.year}</Year>
										</PressLink>
									) : (
										<PressLink href={article.url} target='_blank' rel='noreferrer noopener'>
											<PressHeader>
												<PressLogo src={article.logo.asset.url} alt={article.title} />
											</PressHeader>
											<PressTitle>{article.headline}</PressTitle>
											<Year>{article.year}</Year>
										</PressLink>
									)}
								</PressArticle>
							))}
						</React.Fragment>
					)}
				</Section>
			</Press>
			{invterviews.length > 0 && (
				<Press>
					<Section className='title'>
						<PageTitle>Interviews</PageTitle>
						<PressSubtitle>
							For press inquiries, please contact{' '}
							<a href='mailto:hello@quise.design' target='_blank' rel='noopener noreferrer'>
								hello@quise.design
							</a>
						</PressSubtitle>
					</Section>
					<Section className='list'>
						{invterviews.map((podcast, index) => (
							<PressArticle key={`podcast_${index}`} className='no-hover'>
								{podcast.applePodcast ? (
									<PodcastWrapper>
										<PodcastTitle>{podcast.title}</PodcastTitle>
										<Podcast>
											<div
												dangerouslySetInnerHTML={{
													__html: podcast.applePodcast,
												}}
											/>
										</Podcast>
									</PodcastWrapper>
								) : (
									<PressLink href={podcast.other} rel='noreferrer noopener' target='_blank'>
										<PressHeader>{podcast.logo && <PressLogo src={podcast.logo.asset.url} alt={podcast.title} />}</PressHeader>
										<PressTitle>{podcast.title}</PressTitle>
									</PressLink>
								)}
							</PressArticle>
						))}
					</Section>
				</Press>
			)}
		</React.Fragment>
	)
};

export default PressPage;
